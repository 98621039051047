/**
 * 时间格式化
 * type: 格式化类型
 */
export const timeFormat = (time, type = "") => {
  const date = new Date(time);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  hh = hh < 10 ? "0" + hh : hh;
  mm = mm < 10 ? "0" + mm : mm;
  ss = ss < 10 ? "0" + ss : ss;
  let result = "";
  type = type.toLocaleLowerCase();
  switch (type) {
    case "yyyy-mm-dd":
      result = `${y}-${m}-${d}`;
      break;
    case "mm-dd":
      result = `${m} - ${d}`;
      break;
    case "hh-mm":
      result = `${hh} : ${mm}`;
      break;
    default:
      result = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
      break;
  }
  return result;
};
export const timeFormat1 = (time, type = "") => {
  const date = new Date(time);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  hh = hh < 10 ? "0" + hh : hh;
  mm = mm < 10 ? "0" + mm : mm;
  ss = ss < 10 ? "0" + ss : ss;
  let result = "";
  type = type.toLocaleLowerCase();
  switch (type) {
    case "yyyy-mm-dd":
      result = `${y}-${m}-${d}`;
      break;
    case "mm-dd":
      result = `${m} - ${d}`;
      break;
    case "hh-mm":
      result = `${hh} : ${mm}`;
      break;
    default:
      result = `${y}-${m}-${d} ${hh}:${mm}`;
      break;
  }
  return result;
};
export const timeFormat2 = (time, type = "") => {
  const date = new Date(time);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  hh = hh < 10 ? "0" + hh : hh;
  mm = mm < 10 ? "0" + mm : mm;
  ss = ss < 10 ? "0" + ss : ss;
  let result = "";
  type = type.toLocaleLowerCase();
  switch (type) {
    case "yyyy-mm-dd":
      result = `${y}-${m}-${d}`;
      break;
    case "mm-dd":
      result = `${m} - ${d}`;
      break;
    case "hh-mm":
      result = `${hh} : ${mm}`;
      break;
    default:
      result = `${y}/${m}/${d}`;
      break;
  }
  return result;
};

export const colorHexToRgb = (hexStr, opacity) => {
  //rgb颜色值的正则表达式
  const reg =
    /^(rgba|rgb|RGBA|RGB)\([\s]*[0-9]+[\s]*,[\s]*[0-9]+[\s]*,[\s]*[0-9]+[\s]*(,[\s]*[0-9.]+[\s]*)*\)$/;
  if (reg.test(hexStr)) {
    returnhexStr;
  } else {
    hexStr = hexStr.toLowerCase();
    if (hexStr.length === 4) {
      letcolorNew = "#";
      for (leti = 1; i < 4; i += 1) {
        conststr = hexStr.slice(i, i + 1);
        colorNew += str + str;
      }
      hexStr = colorNew;
    }
    const rgbArray = [];
    for (let i = 1; i < hexStr.length; i += 2) {
      if (i < 7) {
        rgbArray.push(parseInt("0x" + hexStr.slice(i, i + 2)));
      }
      if (i >= 7 && opacity) {
        const str = hexStr.slice(i, i + 2);
        rgbArray.push(
          /^[a-f0-9]{2}$/.test(str)
            ? parseInt(`0x${str}`) / 255
            : (Number(str) / 100).toString()
        );
      }
    }
    return opacity ? "rgba(" + rgbArray.join(",") + ",0.08)" : "rgb(" + rgbArray.join(",") + ")";
  }
};
export const colorHexToRgb1 = (hexStr, opacity) => {
  //rgb颜色值的正则表达式
  const reg =
    /^(rgba|rgb|RGBA|RGB)\([\s]*[0-9]+[\s]*,[\s]*[0-9]+[\s]*,[\s]*[0-9]+[\s]*(,[\s]*[0-9.]+[\s]*)*\)$/;
  if (reg.test(hexStr)) {
    returnhexStr;
  } else {
    hexStr = hexStr.toLowerCase();
    if (hexStr.length === 4) {
      letcolorNew = "#";
      for (leti = 1; i < 4; i += 1) {
        conststr = hexStr.slice(i, i + 1);
        colorNew += str + str;
      }
      hexStr = colorNew;
    }
    const rgbArray = [];
    for (let i = 1; i < hexStr.length; i += 2) {
      if (i < 7) {
        rgbArray.push(parseInt("0x" + hexStr.slice(i, i + 2)));
      }
      if (i >= 7 && opacity) {
        const str = hexStr.slice(i, i + 2);
        rgbArray.push(
          /^[a-f0-9]{2}$/.test(str)
            ? parseInt(`0x${str}`) / 255
            : (Number(str) / 100).toString()
        );
      }
    }
    return opacity ? "rgba(" + rgbArray.join(",") + ",0.3)" : "rgb(" + rgbArray.join(",") + ")";
  }
};

export const generate_splits_recursive = (number) => {
  if (number <= 4){
      return [number]
  }

  for(let i = 3;i >= 0;i--){
    if(number >= i){
      return generate_splits_recursive(number - i) + [i]
    }
  }
}