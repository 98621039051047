<template>
    <div class="Login-bg">
        <div class="Login-Box">
            <div class="Login-logo"></div>
            <div class="Login-title">
                databoard.houbo
            </div>
            <div class="Login-Form">
                <a-form :model="formState" name="basic" autocomplete="off" @finish="login">
                    <a-form-item name="userName">
                        <a-input v-model:value="formState.userName" placeholder="请输入用户名">
                            <template #prefix>
                                <mail-filled style="color: white;" />
                            </template>
                        </a-input>
                    </a-form-item>

                    <a-form-item name="userPwd">
                        <a-input type="password" v-model:value="formState.userPwd" placeholder="请输入密码">
                            <template #prefix>
                                <lock-filled style="color: white;" />
                            </template>
                        </a-input>
                    </a-form-item>


                    <div class="Error-Tips" v-show="ifError">
                        用户名或密码错误
                    </div>

                    <a-form-item style="margin-top: 30px;">
                        <a-button type="primary" html-type="submit">登 录</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="Houbo">
            沪ICP备16036491号-2 Copyright 2019 Houbo. All Rights Reserved. 厚薄公司 版权所有
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { MailFilled, LockFilled } from '@ant-design/icons-vue';
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { loginPost } from "../api/login"
import cookie from 'js-cookie';
interface FormState {
    userName: string;
    userPwd: string;
}

export default defineComponent({
    components: {
        MailFilled,
        LockFilled,
    },
    mounted() {
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const formState = reactive<FormState>({
            userName: '',
            userPwd: '',
        });

        const ifError = ref(false)

        const login = (values) => {
            loginPost(values).then(res => {
                if (values.userName == "") {
                    ifError.value = true
                } else {
                    if (res.data.code == "1001") {
                        ifError.value = true
                    } else {
                        let token = `Bear ${res.data.data.token}`
                        cookie.set('token', token, {expires: 7});
                        cookie.set("username", res.data.data.userName,{expires: 7})
                        cookie.set("user", res.data.data.nickname,{expires: 7})
                        store.dispatch("setUsername", [res.data.data.userName,res.data.data.nickname])
                        router.push("/")
                    }
                }
            })
        };

        return {
            formState,
            login,
            ifError,
            store
        };
    },
})
</script>
<style scoped>
.Login-bg {
    height: 100vh;
    background-image: url(../../public/loginbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.Login-Box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Login-logo {
    margin: 0 auto;
    background-image: url(../../public/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 190px;
    height: 122px;
}

.Login-title {
    width: 540px;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-top: 60px;
}

.Login-Form {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 10px 35px 10px;
    margin-top: 40px;
}

.ant-input-affix-wrapper {
    background-color: black;
    border: none;
    width: 400px;
    height: 50px;
    line-height: 50px;
}

.ant-btn {
    width: 400px;
    height: 50px;
    font-size: 2px;
    background-color: #6E91AF;
    border: none;
    /* color: white; */
}

.Houbo {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 65px;
    line-height: 65px;
    font-size: 1px;
    color: white;
}

.Error-Tips {
    position: fixed;
    color: red;
    top: 430px;
    text-align: center;
    font-size: xx-small;
    width: 400px;
}

.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background-color: black;
}

.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: black;
}
.Login-Form .ant-input-affix-wrapper{
    padding: 0 0 0 11px;
}
.Login-Form .ant-input-affix-wrapper .ant-input-suffix{
    margin: 0;
}
</style>