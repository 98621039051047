import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import Login from "../views/Login.vue";
import Databoard from "../views/Databoard.vue";
import quickLogin from "../views/quickLogin.vue";
import cookie from "js-cookie";

const router = createRouter({
  //指定路由的模式,此处使用的是hash模式

  history: createWebHistory(),

  //路由地址

  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/home",
      name: "home",
      component: Databoard,
    },
    {
      path: "/quickLogin",
      name: "quickLogin",
      component: quickLogin,
    },
  ],
});
router.beforeEach((to, from, next) => {
  const token = cookie.get("token");
  if (to.path === "/login") next();
  if (to.path === "/quickLogin") next();
  if (token) {
    next();
  } else {
    next("/login");
  }
});
export default router;
