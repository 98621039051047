<script lang="ts">
import { defineComponent } from 'vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default defineComponent({
  setup() {
    return {
      locale: zhCN
    };
  },
})
</script>

<template>
  <div>
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<style lang="less">
// @import "./global.less";
* {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.ant-modal-content .ant-input-affix-wrapper{
  height: 30px !important;
  font-size: 12px !important;
}

.Login-Box .ant-input-affix-wrapper>input.ant-input {
  background-color: black !important;
  color: white !important;
  font-size: 2px;
  margin-left: 5px;
}

.drag-item .ant-input-affix-wrapper>input.ant-input {
  background-color: white !important;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: black !important;
}

.ant-col-offset-8 {
  margin-left: 0 !important;
}

.Header .ant-card-body {
  padding: 0;
}

.Filter .ant-card-body {
  padding: 0;
}

// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border-color: #F302AA !important;
//   box-shadow: 0 0 2px 2px rgba(249, 43, 187, 0.2) !important;
// }

.Pagination .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

// .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
//   border-color: #F302AA !important;
// }

.ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
}

// .settime .ant-picker-range .ant-picker-active-bar {
//   background: #F302AA !important;
// }

.Table .ant-card-body {
  padding: 0 !important;
}

.Table .ant-table-thead>tr>th {
  background: none;
}

.ant-table-content tr {
  height: 65px;
}

.Table .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.Pagination .ant-pagination-options {
  float: left;
}

.Pagination .ant-pagination-item-active {
  background: #F302AA;
}

.Pagination .ant-pagination-item-active a {
  color: white;
}

.Pagination .ant-pagination-item,
.Pagination .ant-pagination-prev .ant-pagination-item-link,
.Pagination .ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.Pagination .ant-pagination-item:hover,
.Pagination .ant-pagination-item:hover a,
.Pagination .ant-pagination-prev .ant-pagination-item-link:hover,
.Pagination .ant-pagination-prev .ant-pagination-item-link:hover a,
.Pagination .ant-pagination-next .ant-pagination-item-link:hover,
.Pagination .ant-pagination-next .ant-pagination-item-link:hover a {
  color: #F302AA;
}

.ant-modal {
  width: 1200px !important;
}

// .ant-modal {
//   width: 1000px !important;
// }

.ant-modal-body {
  height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
}

.ant-picker {
  width: 100%;
  border-radius: 5px !important;
}

.ant-input-number {
  border-radius: 5px !important;
  width: 100% !important;
}

.ant-input-number-group-addon {
  border: none !important;
  background-color: white !important;
}

.drag-item .ant-input {
  border-radius: 5px !important;
}

.drag-item .ant-form {
  width: 80%;
  float: left;
  // pointer-events: none !important;
}

.ant-modal-footer {
  text-align: left !important;
}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  float: left !important;
  margin-right: 8px !important;
  margin-left: 0px !important;
  background-color: #F302AA !important;
  border: 1px solid #F302AA !important;
}

.Statistics .ant-row {
  margin-bottom: 12px;
}

.Statistics .ant-row:last-child {
  margin-bottom: 0px;
}

.ant-select-multiple .ant-select-selector::after {
  color: rgba(0, 0, 0, 0) !important;
}

.ant-input-affix-wrapper > input.ant-input{
  // color:black !important;
  // background-color: white !important;
  font-size: 12px !important;
}

// .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
//   border-color: #F302AA !important;
// }

// .settime .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
//   background: #F302AA !important;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
//   background: #fff0f6 !important;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
//   background: #fff0f6 !important;
// }

// .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
//   background: #fff0f6 !important;
// }
</style>
