import { createStore } from "vuex";
import {
  getCourseSeries,
  getCourses,
  getLists,
  getPackage,
} from "../api/databoard";
import moment from "moment";
import cookie from "js-cookie";
import { timeFormat } from "../util/timeFormat";

const store = createStore({
  state() {
    return {
      username: cookie.get("username"),
      user: cookie.get("user"),
      courseSeries: [],
      packages: [],
      courses: [],
      projectTeams:[],
      teamCourse:[],
      data: [],
      total: 0,
      options: [
        {
          id: 1,
          name: "报名中",
        },
        {
          id: 2,
          name: "等待付款",
        },
        {
          id: 3,
          name: "等待付款（阶段1）",
        },
        {
          id: 4,
          name: "等待付款（阶段2）",
        },
        {
          id: 5,
          name: "完成付款",
        },
        {
          id: 6,
          name: "报名成功",
        },
        {
          id: 7,
          name: "已取消",
        },
      ],
    };
  },
  actions: {
    setUsername(context, [value1, value2]) {
      context.commit("setUsername", [value1, value2]);
    },
    setCourseSeries(context) {
      getCourseSeries().then((res) => {
        context.commit("setCourseSeries", res.data.data);
      });
    },
    setPackages(context, data) {
      context.commit("setPackages", data);
    },
    setTcourse(context, data) {
      context.commit("setTcourse", data);
    },
    setTeams(context, data) {
      context.commit("setTeams", data);
    },
    setSeries(context, data) {
      context.commit("setSeries", data);
    },
    setCourses(context, [key, value]) {
      context.commit("setCourses", [key, value]);
    },
    setData(context, data) {
      context.commit("setData", data);
    },
  },
  mutations: {
    setUsername(state, [value1, value2]) {
      state.username = value1;
      state.user = value2;
    },
    setCourseSeries(state, value) {
      value.map((item) => {
        item.id = `-${item.id}`;
        item.courses.map((it) => {
          it.name = `${it.name}-${item.name}`;
        });
      });
      state.courseSeries = value;
    },
    setPackages(state, value) {
      state.packages = value;
    },
    setTeams(state, value) {
      state.projectTeams = value;
    },
    setTcourse(state, value) {
      let arr = []
      value.map((item,index) => {
        if(item.type == 1){
          if(state.courses.filter(ite => ite.id == item.id)[0]){
            arr.push({id:item.id,type:item.type,name:state.courses.filter(ite => ite.id == item.id)[0].name,course:state.courses.filter(ite => ite.id == item.id)[0].courses})
          }
        }else{
          if(state.packages.filter(ite => ite.id == item.id)[0]){
            arr.push({id:item.id,type:item.type,name:state.packages.filter(ite => ite.id == item.id)[0].name,course:state.packages.filter(ite => ite.id == item.id)[0].courses})
          }
        }
      })
      state.teamCourse = arr;
    },
    setSeries(state, value) {
      state.courses = value;
    },
    setCourses(state, [key, value]) {
      value.map((item) => {
        item.parent = key;
      });
      state.courseSeries.map((item) => {
        if (item.id == key) {
          item.Courses = value;
        }
      });
    },
    setData(state, value) {
      value.list.map((item) => {
        item.status = state.options.filter(
          (it) => it.id == item.status
        )[0].name;
        item.createTime = timeFormat(item.createTime * 1000);
        if (
          item.coursePackageSeriesName == "" &&
          item.coursePackageName == ""
        ) {
          item.courseSeriesName = "";
        } else {
          item.courseSeriesName = `${item.coursePackageSeriesName}-${item.coursePackageName}`;
        }
      });
      state.data = value.list;
      state.total = value.totalNumber;
    },
  },
  getters: {},
  modules: {},
});

export default store;
