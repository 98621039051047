import axios from "./request"

export const loginPost = (data) => {
    return axios({
        url: "/login",
        method: "post",
        data,
        config: {
            // headers: {
            //     'Request-Type': 'wechat'
            // },
            timeout: 10000
        }
    })
}
